import React from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';
import { Link } from 'gatsby';

const Root = styled.div`
  padding: 0;
  margin: 0;
  color: ${colors.gray1};
  font-size: 16px;
  line-height: 22px;
  display: flex;

  p {
    color: ${colors.gray1};
    font-size: 16px;
    line-height: 22px;
  }
`

const Title = styled.div`
  width: 55px;
  padding: 0 5px 0 0;
  margin: 0 0 16px;
`
const Content = styled.div`
  width: calc(100% - 60px);

  a {
    text-decoration: none;
    box-shadow: none;
    display: inline-block;
    margin: 0 8px 8px 0;

    &:hover {
      box-shadow: none;

      svg path {
        fill: ${colors.ramotionBlue};
      }
    }
  }
`


const PostShareBlock = ({title, slug}) => {
  return (
    <Root>
      <Title>
        <p>Share:</p>
      </Title>
      <Content>
        <a href={`https://twitter.com/share?source=tweetbutton&text=${title}&url=https://www.ramotion.com/blog/${slug}`} target="_blank">  
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6396 14.9965H13.9357L9.34221 8.96325H10.0974L14.6396 14.9965Z" fill="#292728"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06441 3H16.9356C19.1803 3 21 4.82813 21 7.08324V16.9168C21 19.1719 19.1803 21 16.9356 21H7.06441C4.8197 21 3 19.1719 3 16.9168V7.08324C3 4.82813 4.8197 3 7.06441 3ZM15.6514 8.23957H14.3815L12.2887 10.6427L10.4795 8.23957H7.85932L10.99 12.3528L8.0228 15.7601H9.29334L11.5838 13.1313L13.5852 15.7601H16.1407L12.8772 11.4253L15.6514 8.23957Z" fill="#292728"/>
          </svg>
        </a>
        
        <a href={`https://www.facebook.com/sharer.php?u=https://www.ramotion.com/blog/${slug}`} target="_blank">  
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12.0607C22 6.504 17.5233 2 12 2C6.47667 2 2 6.504 2 12.0607C2 17.0833 5.656 21.2453 10.4373 22V14.9693H7.89867V12.06H10.4373V9.844C10.4373 7.32267 11.93 5.92933 14.2147 5.92933C15.308 5.92933 16.4533 6.126 16.4533 6.126V8.602H15.1913C13.9493 8.602 13.5627 9.378 13.5627 10.174V12.0607H16.336L15.8927 14.9687H13.5627V22C18.344 21.2453 22 17.0833 22 12.0607Z" fill="#292728"/>
          </svg>
        </a>

        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.ramotion.com/blog/${slug}&title=${title}`} target="_blank">  
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 4.32353V19.6765C21 20.0275 20.8606 20.3641 20.6123 20.6123C20.3641 20.8606 20.0275 21 19.6765 21H4.32353C3.97251 21 3.63586 20.8606 3.38765 20.6123C3.13944 20.3641 3 20.0275 3 19.6765V4.32353C3 3.97251 3.13944 3.63586 3.38765 3.38765C3.63586 3.13944 3.97251 3 4.32353 3H19.6765C20.0275 3 20.3641 3.13944 20.6123 3.38765C20.8606 3.63586 21 3.97251 21 4.32353ZM8.29412 9.88235H5.64706V18.3529H8.29412V9.88235ZM8.53235 6.97059C8.53375 6.77036 8.49569 6.57182 8.42035 6.3863C8.34502 6.20078 8.23387 6.03191 8.09328 5.88935C7.95268 5.74678 7.78537 5.6333 7.60091 5.5554C7.41646 5.47749 7.21846 5.43668 7.01824 5.43529H6.97059C6.5634 5.43529 6.17289 5.59705 5.88497 5.88497C5.59705 6.17289 5.43529 6.5634 5.43529 6.97059C5.43529 7.37777 5.59705 7.76828 5.88497 8.05621C6.17289 8.34413 6.5634 8.50588 6.97059 8.50588C7.17083 8.51081 7.37008 8.47623 7.55696 8.40413C7.74383 8.33202 7.91467 8.2238 8.0597 8.08565C8.20474 7.94749 8.32113 7.78212 8.40223 7.59897C8.48333 7.41582 8.52755 7.21848 8.53235 7.01824V6.97059ZM18.3529 13.2071C18.3529 10.6606 16.7329 9.67059 15.1235 9.67059C14.5966 9.6442 14.0719 9.75644 13.6019 9.9961C13.1318 10.2358 12.7328 10.5945 12.4447 11.0365H12.3706V9.88235H9.88235V18.3529H12.5294V13.8476C12.4911 13.3862 12.6365 12.9283 12.9339 12.5735C13.2313 12.2186 13.6567 11.9954 14.1176 11.9524H14.2182C15.06 11.9524 15.6847 12.4818 15.6847 13.8159V18.3529H18.3318L18.3529 13.2071Z" fill="#292728"/>
          </svg>
        </a>
        
      </Content>
    </Root>
  );
};

export default PostShareBlock;
