import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import {
  ReadNextWrapper,
  PageInner,
  PostCardMeta,
  PostCardMetaContent,
  PostCard,
  PostCardContent,
} from '../components/Elements';
import { PostCardImageLink, PostImage } from '../templates/BlogList';

import { timeToRead } from '../utils';

const ReadNext = styled.aside`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  h2 {
    color: #fff;
  }

  p {
    color: hsla(0, 0%, 100%, 0.6);
  }

  a {
    color: hsla(0, 0%, 100%, 0.8);
  }

  ${PostCard} {
    border: none;
    min-height: 148px;
    border-radius: 3px;
    text-decoration: none;
  }
`;

const ReadNextBlock = ({ readNext }) => {
  return (
    <ReadNextWrapper>
      <PageInner>
        <ReadNext>
          {readNext.map(post => {
            const wordCount = post.mainContent.reduce((acc, cur) => {
              if (!cur.textNode) return acc + 0;
              return acc + cur.textNode.childMarkdownRemark.wordCount.words;
            }, 0);
            return (
              <PostCard key={post.id}>
                <PostCardImageLink to={`/${post.slug}/`}>
                  {post.postImage ? (
                    <PostImage
                      fluid={post.postImage.fluid}
                      alt={post.postImage.alt}
                    />
                  ) : null}
                </PostCardImageLink>

                <PostCardContent>
                  <Link to={`/${post.slug}/`}>
                    <header>
                      <h2>{post.title}</h2>
                    </header>
                    <section>
                      <p>{post.shortDescription}</p>
                    </section>
                  </Link>

                  <PostCardMeta>
                    <PostCardMetaContent>
                      <span>
                        {post.dateOfPublication
                          ? post.dateOfPublication
                          : post.meta.createdAt}{' '}
                        • {timeToRead(wordCount)} min read
                      </span>
                    </PostCardMetaContent>
                  </PostCardMeta>
                </PostCardContent>
              </PostCard>
            );
          })}
        </ReadNext>
      </PageInner>
    </ReadNextWrapper>
  );
};

export default ReadNextBlock;
