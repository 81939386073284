import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../consts/style';

const SiteNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
  margin: 48px auto 0;
  padding: 0 20px;
  position: relative;
  max-width: 1080px;

  &:after {
    content: '';
    height: 1px;
    display: block;
    border-bottom: 1px solid ${colors.gray3};
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
  }

  ul {
    width: 100%;
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    position: relative;

    ::-webkit-scrollbar {
      display: none;
    }

    li {
      padding: 0 8px;
      color: ${colors.gray1};
      font-size: 14px;
      line-height: 1;
      z-index: 10;

      &:first-child {
        padding-left: 0;
      }

      a {
        display: block;
        color: ${colors.gray1};
        position: relative;
        white-space: nowrap;
        padding: 0 0 12px;
        border-bottom: 1px solid transparent;

        &.active,
        &:hover {
          color: ${colors.black2};
          text-decoration: none;
          border-bottom: 1px solid ${colors.black2};
        }
      }
    }
  }
`;

export default function TagsNav({ tags, slug }) {
  return (
    <SiteNavWrapper>
      <ul>
        <li key={'id-all-posts'}>
          <Link to={`/`} className={slug === '/' ? 'active' : ''}>
            All Posts
          </Link>
        </li>
        {tags.map(item =>
          !item.hide ? (
            <li key={item.id}>
              <Link
                to={`/tag/${item.slug}/`}
                className={item.slug === slug ? 'active' : ''}
              >
                {item.name}
              </Link>
            </li>
          ) : null
        )}
      </ul>
    </SiteNavWrapper>
  );
}
