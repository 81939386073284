import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import { colors, font } from '../consts/style';

const Root = styled.div`
  background: ${colors.lighBlue};
  padding: 40px;
  margin: 0 0 24px;
  border-radius: 4px;

  h3 {
    ${font.h3}
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 24px;
  }

  form {
    margin: 0;
  }

  label {
    display: block;
    margin: 0;
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${colors.gray2};
    border-radius: 4px;
    background: white;
    margin: 0 0 16px;
    padding: 0 16px;
    font-size: 16px;
    color: ${colors.black2};

    &::placeholder {
      color: ${colors.gray2};
      opacity: 1;
    }
  }

  button {
    height: 40px;
    background: ${colors.ramotionBlue};
    color: white;
    font-size: 16px;
    font-weight: normal;
    border-radius: 4px;
    margin: 0;
    transition: all 0.3s ease-in-out;

    &.full-width {
      width: 100%;
    }

    &:hover {
      background: ${colors.ramotionBlueHover};
    }
  }
`;


const FormCols = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-gap: 24px;

  &.aside {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  input,
  button {
    margin: 0;
  }

  button {
    width: 110px;
  }

  &.aside {
    button {
      width: 100%;
    }
  }
`;

const ContactUsForm = ({
  slug,
  formId,
  isFormSubmitted,
  onFormSubmit,
  onBackToForm,
}) => {
  let firstName = '';
  const formRef = useRef(null);

  const completeForm = () => {
    onFormSubmit();
  };

  const backToForm = () => {
    onBackToForm();
    formRef.current.reset();
  };

  const handleSubmit = event => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.set('last-name', firstName);

    fetch(`${withPrefix('/')}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        completeForm();
      })
      .catch(error => alert(error));
  };

  const handleChange = e => {
    firstName = e.target.value;
  };

  useEffect(() => {
    formRef.current.addEventListener('submit', handleSubmit);
  }, []);

  const FormContent = () => (
    <>
      <input type="hidden" name="form-name" value="blog-contact-form" />

      <FormCols className={formId === 'contact-form-aside' ? 'aside' : ''}>
        <div>
          <h3>
            {formId === 'contact-form-aside'
              ? 'Partner with us'
              : 'Unlock your business potential with us'}
          </h3>
          <p>
            {formId === 'contact-form-aside'
              ? 'Unlock your business potential with our committed team driving your success.'
              : 'Empower your business with tailored strategy, innovative design, and seamless development. Ready to take your company to the next level?'}
          </p>
        </div>

        <FormRow>
          <label>
            <input
              type="text"
              name="first-name"
              placeholder="Name"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              type="text"
              name="job-title"
              placeholder="Job Title"
              required
            />
          </label>
          <label>
            <input
              type="email"
              name="email"
              placeholder="Company email"
              required
            />
          </label>

          <input name="last-name" type="hidden" value="" />
          <input name="source" type="hidden" value="blog/article" />
          <input
            name="campaign"
            type="hidden"
            value={`${
              formId === 'contact-form-aside'
                ? 'aside-cta-widget'
                : 'footer-cta-widget'
            }`}
          />
          <input name="conversion-page" type="hidden" value={`/blog/${slug}`} />

          <button
            type="submit"
            className={formId === 'contact-form-aside' ? 'full-width' : ''}
          >
            Contact us
          </button>
        </FormRow>
      </FormCols>
    </>
  );

  const ThankYouContent = () => (
    <div className="thank-you-content">
      <h3>Your request has been sent</h3>
      <p>Our managers will contact you in a 24 hours</p>
      <button
        type="button"
        onClick={backToForm}
        className={formId === 'contact-form-aside' ? 'full-width' : ''}
      >
        Got it
      </button>
    </div>
  );

  return (
    <Root>
      <form
        ref={formRef}
        id={formId}
        name="blog-contact-form"
        method="POST"
        data-netlify="true"
      >
        {isFormSubmitted ? <ThankYouContent /> : <FormContent />}
      </form>
    </Root>
  );
};

export default ContactUsForm;
