import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';
import { Link } from 'gatsby';

const Root = styled.div`
  padding: 0;
  margin: 0;
  color: ${colors.gray1};
  font-size: 16px;
  line-height: 22px;
  display: flex;

  p {
    color: ${colors.gray1};
    font-size: 16px;
    line-height: 22px;
  }

  a {
    color: ${colors.gray1};
    text-decoration: none;
    box-shadow: none;

    &:hover {
      color: ${colors.ramotionBlue};
      box-shadow: none;
    }
  }
`

const Title = styled.div`
  width: 55px;
  padding: 0 5px 0 0;
  margin: 0 0 16px;
`
const Content = styled.div`
  width: calc(100% - 60px);
`


const PostTagsBlock = ({ tagCloud }) => {
  return (
    <Root>
      <Title>
        <p>Tags:</p>
      </Title>
      <Content>
        <p>
          {tagCloud.map((tag, i) => (
            <Fragment key={tag.id}>
              <Link to={`/tag/${tag.slug}/`}>
                {tag.name}
              </Link>
              {i < tagCloud.length - 1 && ', '}
            </Fragment>
          ))}
        </p>
      </Content>
    </Root>
  );
};

export default PostTagsBlock;
