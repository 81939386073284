import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';
import Img from 'gatsby-image';

import {
  PostInnerImage,
  PostInnerVideo,
  FeaturedSnippet,
} from '../components/Elements';

const TwitterCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2em 0;

  .twitter-tweet {
    opacity: 0;

    &-rendered {
      opacity: 1;
    }
  }
`;

const ContentTable = styled.div`
  width: 100%;
  margin: 64px 0;
  position: relative;

  section {
    width: 100%;
    margin: 0;
    position: relative;

    > div {
      width: 100%;
      margin: 0;
      overflow-x: auto;
    }
    /* &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 64px;
      top: 0;
      bottom: 0;
    }

    &:before {
      bottom: 10px;
      width: 20px;
      left: 128px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 100),
        rgba(255, 255, 255, 0)
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 100)
      );
    } */
  }

  table {
    width: auto;
    max-width: 100%;
    min-width: 50%;
    border-collapse: collapse;
    margin: 0 0 10px 0;

    th {
      color: ${colors.gray1};
      font-weight: normal;
      font-size: 16px;
      padding: 12px;
      white-space: nowrap;
      max-width: 340px;
      min-width: 180px;

      /* &:first-child {
        padding-left: 0;
        position: absolute;
        width: 128px;
        left: 0;
        background: #fff;
      } */
    }

    td {
      font-size: 16px;
      color: ${colors.black2};
      padding: 12px;
      vertical-align: baseline;
      border-top: 1px solid ${colors.gray3};
      max-width: 340px;
      min-width: 180px;

      /* &:first-child {
        padding-left: 0;
        position: absolute;
        width: 128px;
        left: 0;
        background: #fff;
      } */

      strong {
        font-weight: 600;
      }
    }
  }
`;

const ContentHighlight = styled.mark`
  display: block;
  background: transparent;
  width: 100%;
  margin: 64px 0;
  border-left: 4px solid #ffe1a8;
  padding-left: 24px;
`;

const ContentDownload = styled.div`
  width: 100%;
  margin: 64px 0;
`;

const ContentDownloadFile = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 0 16px;

  a {
    color: ${colors.ramotionBlue};
    text-decoration: none;
    margin: 0 16px 0 0;
    box-shadow: none;

    &:hover {
      color: ${colors.ramotionBlueHover};
      box-shadow: none;
    }
  }

  .fiv-viv {
    width: 24px;
    margin: 0 8px 0 0;
    line-height: inherit;
  }
`;

const QuoteBlock = styled.blockquote`
  width: 100%;
  border: 1px solid ${colors.gray3};
  border-radius: 4px;
  padding: 48px 40px 32px;
  text-align: center;
  margin: 64px 0;
`;

const QuoteBlockAuthor = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 0 20px;

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 0 16px;
  }

  strong {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    margin: 0 0 4px;
  }

  span {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 4px;
  }
`;

const CodeBlock = styled.div`
  width: 100%;
  margin: 64px 0;
  position: relative;

  code {
    display: block;
    background: #f3f8fe;
    padding: 24px;
    border-radius: 4px;
    border-left: 68px solid #e7f0fa;
    max-width: 100%;
    font-size: 16px;
    line-height: 1.6;
    color: #51668f;
    font-family: 'IBM Plex Mono', monospace;
    white-space: pre-wrap;

    @media (max-width: 767px) {
      border-left: 16px solid #e7f0fa;
    }
  }
`;

const CopyBtn = styled.span`
  position: absolute;
  right: 24px;
  top: 24px;
  color: ${colors.ramotionBlue};
  font-size: 16px;
  line-height: 1.6;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in;

  &:hover {
    color: ${colors.ramotionBlueHover};
    cursor: pointer;
  }

  ${CodeBlock}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const ProsConsBlock = styled.div`
  width: 100%;
  margin: 64px 0;
  position: relative;

  h3 {
    margin: 40px 0;
  }
`;

const ProsConsCols = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 46%;
  }

  h4 {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.gray1};
    border-bottom: 1px solid ${colors.gray3};
    margin: 0 0 16px;
    padding: 0 0 16px;
  }
`;

const ColContent = styled.div`
  padding: 0 8px;

  ul li {
    line-height: 1.8;
    margin: 0 0 16px;

    &:before {
      left: 2px;
      top: 14px;
      width: 4px;
      height: 4px;
    }
  }
`;


const PostContentBlock = ({ block }) => {
  const copyEvent = () => {
    let copyText = document.querySelector('#copy').textContent;
    navigator.clipboard.writeText(copyText);
  };

  return (
    <Fragment>
      {block.model.apiKey === 'anchored_post_heading' && (
        <h2 className="anchored-heading" id={`section-${block.titleId}`}>
          {block.title}
        </h2>
      )}
      {block.model.apiKey === 'featured_snippet_block' && (
        <FeaturedSnippet className="featured-snippet">
          <h3>{block.title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: block.contentNode.childMarkdownRemark.html,
            }}
          />
        </FeaturedSnippet>
      )}
      {block.model.apiKey === 'text_block' && (
        <div
          dangerouslySetInnerHTML={{
            __html: block.textNode.childMarkdownRemark.html,
          }}
        />
      )}
      {block.model.apiKey === 'image_block' &&
        block.images.map(image => (
          <PostInnerImage key={image.originalId}>
            {block.fullWidth ? (
              block.imageLink ? (
                <a
                  href={block.imageLink}
                  rel={block.imageLinkNofollow ? 'nofollow' : ''}
                >
                  <Img fluid={image.fluid} alt={image.alt} />
                </a>
              ) : (
                <Img fluid={image.fluid} alt={image.alt} />
              )
            ) : block.imageLink ? (
              <a
                href={block.imageLink}
                rel={block.imageLinkNofollow ? 'nofollow' : ''}
              >
                <img src={image.fluid.src} alt={image.alt} />
              </a>
            ) : (
              <img src={image.fluid.src} alt={image.alt} />
            )}
            {block.imageCaptionNode.childMarkdownRemark.html ? (
              <figcaption
                dangerouslySetInnerHTML={{
                  __html: block.imageCaptionNode.childMarkdownRemark.html,
                }}
              />
            ) : image.title ? (
              <figcaption>{image.title}</figcaption>
            ) : null}
          </PostInnerImage>
        ))}
      {block.model.apiKey === 'video_block' && (
        <PostInnerVideo>
          <div className="video-wrapper">
            {block.internalVideo ? (
              <video preload="metadata" loop autoPlay muted>
                <source src={block.internalVideo.url} type={block.internalVideo.mimeType} />
              </video>
            ) : (
              <>
                {block.video.provider === 'vimeo' ? (
                  <iframe
                    src={
                      'https://player.vimeo.com/video/' + block.video.providerUid
                    }
                    width="{video_width}"
                    height="{video_height}"
                    frameborder="0"
                    title="{video_title}"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <iframe
                    src={`https://www.youtube.com/embed/${block.video.providerUid}`}
                    width="{video_width}"
                    height="{video_height}"
                    frameborder="0"
                    title={block.video.title}
                    allowFullScreen
                  ></iframe>
                )}
              </>
            )}
          </div>
          {block.videoCaptionNode.childMarkdownRemark.html ? (
            <figcaption
              dangerouslySetInnerHTML={{
                __html: block.videoCaptionNode.childMarkdownRemark.html,
              }}
            />
          ) : null}
        </PostInnerVideo>
      )}
      {block.model.apiKey === 'twitter_block' && (
        <TwitterCard
          dangerouslySetInnerHTML={{
            __html: block.embededTweetNode.childMarkdownRemark.html,
          }}
        />
      )}
      {block.model.apiKey === 'table_block' && (
        <ContentTable>
          <h3>{block.title}</h3>
          <section>
            <div
              dangerouslySetInnerHTML={{
                __html: block.tableNode.childMarkdownRemark.html,
              }}
            />
          </section>
        </ContentTable>
      )}
      {block.model.apiKey === 'quote_block' && (
        <QuoteBlock>
          <QuoteBlockAuthor>
            <img src={block.image.url} alt={block.name} />
            <strong>{block.name}</strong>
            <span>{block.company}</span>
          </QuoteBlockAuthor>
          <div
            dangerouslySetInnerHTML={{
              __html: block.quoteNode.childMarkdownRemark.html,
            }}
          />
        </QuoteBlock>
      )}
      {block.model.apiKey === 'highlight_block' && (
        <ContentHighlight
          dangerouslySetInnerHTML={{
            __html: block.highlightTextNode.childMarkdownRemark.html,
          }}
        />
      )}
      {block.model.apiKey === 'download_file_block' && (
        <ContentDownload>
          <h5>{block.title}</h5>
          {block.file.map(file => (
            <ContentDownloadFile key={file.id}>
              <span className={`fiv-viv fiv-icon-${file.format}`}></span>
              <a href={file.link} download>
                {file.name}
              </a>
              {file.size}
            </ContentDownloadFile>
          ))}
        </ContentDownload>
      )}
      {block.model.apiKey === 'code_block' && (
        <CodeBlock id="copy">
          <div
            dangerouslySetInnerHTML={{
              __html: block.codeNode.childMarkdownRemark.html,
            }}
          />
          <CopyBtn onClick={copyEvent}>Copy</CopyBtn>
        </CodeBlock>
      )}
      {block.model.apiKey === 'proscons_block' && (
        <ProsConsBlock>
          <h3>{block.title}</h3>
          <ProsConsCols>
            <div>
              <h4>{block.prosTitle ? block.prosTitle : 'Pros'}</h4>
              <ColContent
                dangerouslySetInnerHTML={{
                  __html: block.prosContentNode.childMarkdownRemark.html,
                }}
              />
            </div>
            <div>
              <h4>{block.consTitle ? block.consTitle : 'Cons'}</h4>
              <ColContent
                dangerouslySetInnerHTML={{
                  __html: block.consContentNode.childMarkdownRemark.html,
                }}
              />
            </div>
          </ProsConsCols>
        </ProsConsBlock>
      )}
    </Fragment>
  );
};

export default PostContentBlock;
