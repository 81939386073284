import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';

const JsonLd = ({ children }) => {
  return (
    <HelmetDatoCms>
      {children ? (
        <script type="application/ld+json">{`${children}`}</script>
      ) : null}
    </HelmetDatoCms>
  );
};

export default JsonLd;
