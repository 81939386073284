import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';
import { graphql, Link, withPrefix } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs';
import TagsNav from '../components//TagsNav';
import {
  PageWrapper,
  PageInner,
  PostFeed,
  BlogPostItem,
  PostDate,
  PostLink,
  BlogPostItemContent,
  PostTags,
  FeaturedPostWrapper,
  FeaturedPostFeed,
  FeaturedPostFeedCol,
  PostFeedWrapper,
  AsideWidget,
  Aside,
} from '../components/Elements';
import PostMetaBlock from '../components/PostMetaBlock';
import Pagination from '../components/Pagination';

const HomeFeaturedPostFeed = styled(FeaturedPostFeed)`
  width: 100%;

  ${BlogPostItem} {
    width: 100%;
  }
`;

export const PostCardImageLink = styled(Link)`
  position: relative;
  display: block;
  overflow: hidden;
  height: 240px;
  border-radius: 4px;
  margin: 0 0 24px;
`;

export const PostImage = styled(Img)`
  height: 100%;
`;

const TagTitle = styled.div`
  width: 100%;
  padding: 64px 0 0;

  @media (max-width: 1023px) {
    padding: 64px 0 0;
  }
`;

const PageTitle = styled.div`
  width: 100%;
  padding: 48px 0 16px;
  display: flex;
  justify-content: center;

  h1 {
    max-width: 500px;
    font-size: 48px;
    line-height: 1.125;
    margin: 0;
    text-align: center;
  }
`;

const BlogList = ({ data, pageContext }) => {
  const { seoMetaTags, title } = data.page;
  const { currentPage, numPages } = pageContext;
  const tags = data.tags.nodes;

  const featuredPostsQty = 4;
  const allPosts = data.posts.edges;
  const firstPagePosts = data.firstPagePosts.edges;
  const firstPost = firstPagePosts.slice(0, 1);
  const faturedPosts = firstPagePosts.slice(1, featuredPostsQty);
  const otherPosts =
    currentPage === 1 ? firstPagePosts.slice(featuredPostsQty) : allPosts;

  const min = 1;
  let length = 4;
  if (length > numPages) length = numPages;

  let start = currentPage - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + numPages - length);

  return (
    <Fragment>
      <SEO meta={seoMetaTags} page={currentPage} />

      <PageTitle>
        <h1>{title}</h1>
      </PageTitle>
      
      <TagsNav tags={tags} slug={`/`} />

      <PageWrapper>
        <PageInner>
          {currentPage === 1 ? (
            <FeaturedPostWrapper>
              <HomeFeaturedPostFeed>
                <FeaturedPostFeedCol>
                  {firstPost.map(post => {
                    const wordCount = post.node.mainContent.reduce(
                      (acc, cur) => {
                        if (!cur.textNode) return acc + 0;
                        return (
                          acc + cur.textNode.childMarkdownRemark.wordCount.words
                        );
                      },
                      0
                    );
                    return (
                      <BlogPostItem key={post.node.id}>
                        <BlogPostItemContent>
                        
                          {post.node.postImage ? (
                            <PostCardImageLink to={`/${post.node.slug}/`}>
                                <PostImage
                                  fluid={post.node.postImage.fluid}
                                  alt={post.node.postImage.alt}
                                />
                            </PostCardImageLink>
                          ) : null}

                          <PostLink href={withPrefix(`/${post.node.slug}/`)}>
                            <header>
                              <span className="h1">{post.node.title}</span>
                            </header>
                          </PostLink>

                          <PostMetaBlock wordCount={wordCount} />
                        </BlogPostItemContent>
                      </BlogPostItem>
                    );
                  })}
                </FeaturedPostFeedCol>
                <FeaturedPostFeedCol>
                  {faturedPosts.map(post => {
                    const wordCount = post.node.mainContent.reduce(
                      (acc, cur) => {
                        if (!cur.textNode) return acc + 0;
                        return (
                          acc + cur.textNode.childMarkdownRemark.wordCount.words
                        );
                      },
                      0
                    );
                    return (
                      <BlogPostItem key={post.node.id}>
                        <BlogPostItemContent>
                          <PostLink href={withPrefix(`/${post.node.slug}/`)}>
                            <header>
                              <span className="h2">{post.node.title}</span>
                            </header>
                          </PostLink>

                          <PostMetaBlock wordCount={wordCount} />
                        </BlogPostItemContent>
                      </BlogPostItem>
                    );
                  })}
                </FeaturedPostFeedCol>
              </HomeFeaturedPostFeed>
            </FeaturedPostWrapper>
          ) : null}

          {currentPage !== 1 ? (
            <TagTitle>
              <h1>#All Articles - Page {currentPage}</h1>
            </TagTitle>
          ) : null}

          <PostFeedWrapper>
            <PostFeed className="all-posts">
              {otherPosts.map(post => {
                const shortDescription = post.node.mainContent
                  .reduce((acc, cur) => {
                    if (!cur.textNode) return acc + '';
                    return acc + cur.textNode.childMarkdownRemark.excerpt;
                  }, '')
                  .split('…')[0];
                const wordCount = post.node.mainContent.reduce((acc, cur) => {
                  if (!cur.textNode) return acc + 0;
                  return acc + cur.textNode.childMarkdownRemark.wordCount.words;
                }, 0);
                return (
                  <BlogPostItem key={post.node.id}>
                    <PostDate>
                      {post.node.dateOfPublication
                        ? post.node.dateOfPublication
                        : post.node.meta.createdAt}
                    </PostDate>
                    <BlogPostItemContent>
                      {post.node.postTags.length > 0 ? (
                        <PostTags>
                          {post.node.postTags.map(tag => (
                            <Link to={`/tag/${tag.slug}/`} key={tag.id}>
                              {tag.name}
                            </Link>
                          ))}
                        </PostTags>
                      ) : null}

                      <PostLink href={withPrefix(`/${post.node.slug}/`)}>
                        <header>
                          <span className="h2">{post.node.title}</span>
                        </header>
                        <section>
                          <p>
                            {post.node.shortDescription
                              ? post.node.shortDescription
                              : shortDescription}
                          </p>
                        </section>
                      </PostLink>

                      <PostMetaBlock wordCount={wordCount} />
                    </BlogPostItemContent>
                  </BlogPostItem>
                );
              })}

              {numPages > 1 ? (
                <Pagination pageContext={pageContext} slug={`/`} />
              ) : null}
            </PostFeed>

            <Aside>
              <AsideWidget>
                <Tabs homePage />
              </AsideWidget>
            </Aside>
          </PostFeedWrapper>
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
};

export const blogQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    page: datoCmsBlogPage {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    firstPagePosts: allDatoCmsBlogPost(
      sort: { fields: [position], order: ASC }
      filter: { firstPagePost: { eq: true } }
    ) {
      ...post
    }
    posts: allDatoCmsBlogPost(
      sort: { fields: [meta___createdAt], order: DESC }
      filter: { firstPagePost: { eq: false } }
      limit: $limit
      skip: $skip
    ) {
      ...post
    }
    tags: allDatoCmsTag(sort: { fields: position }) {
      nodes {
        id
        hide
        name
        slug
        position
      }
    }
  }
  fragment post on DatoCmsBlogPostConnection {
    edges {
      node {
        id
        dateOfPublication(formatString: "MMM D, YYYY")
        meta {
          createdAt(formatString: "MMM D, YYYY")
        }
        mainContent {
          ... on DatoCmsTextBlock {
            id
            textNode {
              childMarkdownRemark {
                excerpt(pruneLength: 200)
                wordCount {
                  words
                }
              }
            }
          }
        }
        title
        slug
        shortDescription
        postImage {
          alt
          title
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        postTags {
          id
          name
          slug
        }
      }
    }
  }
`;

export default BlogList;
