import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import {
  PostLink,
  PostTags,
  PostDate,
  BlogPostItem,
  BlogPostItemContent,
} from './Elements';

import PostMetaBlock from './PostMetaBlock';

import { colors } from '../consts/style';

const ReadAlso = styled.aside`
  width: 100%;
  padding: 60px 0 0;

  & > h2 {
    padding: 0 0 16px;
    margin: 0 0 64px;
    border-bottom: 1px solid ${colors.gray3};
  }
`;

const ReadAlsoBlock = ({ readAlso }) => {
  return (
    <ReadAlso>
      <h2>Related posts:</h2>

      {readAlso.map(post => {
        const shortDescription = post.mainContent
          .reduce((acc, cur) => {
            if (!cur.textNode) return acc + '';
            return acc + cur.textNode.childMarkdownRemark.excerpt;
          }, '')
          .split('…')[0];
        const wordCount = post.mainContent.reduce((acc, cur) => {
          if (!cur.textNode) return acc + 0;
          return acc + cur.textNode.childMarkdownRemark.wordCount.words;
        }, 0);

        return (
          <BlogPostItem key={post.id}>
            <PostDate>
              {post.dateOfPublication
                ? post.dateOfPublication
                : post.meta.createdAt}
            </PostDate>
            <BlogPostItemContent>
              {post.postTags.length > 0 ? (
                <PostTags>
                  {post.postTags.map(tag => (
                    <Link to={`/tag/${tag.slug}/`} key={tag.id}>
                      {tag.name}
                    </Link>
                  ))}
                </PostTags>
              ) : null}

              <PostLink href={`/blog/${post.slug}/`}>
                <header>
                  <h2>{post.title}</h2>
                </header>
                <section>
                  <p>
                    {post.shortDescription
                      ? post.shortDescription
                      : shortDescription}
                  </p>
                </section>
              </PostLink>

              <PostMetaBlock wordCount={wordCount} />
            </BlogPostItemContent>
          </BlogPostItem>
        );
      })}
    </ReadAlso>
  );
};

export default ReadAlsoBlock;
