import React from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';

import { Link } from 'gatsby';

const Root = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0 0 35px !important;
  padding: 0;

  li {
    padding: 0 !important;
    margin: 0 0 5px !important;
    color: ${colors.gray2} !important;
    font-size: 14px;
    line-height: 1.1em !important;

    &:before {
      display: none;
    }

    a {
      color: ${colors.black2};
      text-decoration: none;
      box-shadow: none;

      &:hover {
        color: ${colors.ramotionBlue};
        box-shadow: none;
      }
    }

    span {
      padding: 0 5px;
      display: inline-block;
    }
  }
`;

const Breadcrumbs = ({ title }) => {
  return (
    <Root>
      <li>
        <a href="https://www.ramotion.com/" title="Ramotion">
          Ramotion
        </a>{' '}
        <span>/</span>
      </li>
      <li>
        <Link to="/">Blog</Link> <span>/</span>
      </li>
      <li>{title}</li>
    </Root>
  );
};

export default Breadcrumbs;
